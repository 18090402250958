import React from 'react'
import ServiceBoxItems from './ServicesBoxItems'

// icons
import FootIcon from '../images/foot_icon.svg'
import DumbbellIcon from '../images/dumbbell_icon.svg'
import FitbandIcon from '../images/fitband_icon.svg'

const ServicesBox = () => {
    return (
        <div className="services-box">
            <ServiceBoxItems icon={FootIcon} name={'Cross-Fit'}/>
            <ServiceBoxItems icon={DumbbellIcon} name={'Weight'}/>
            <ServiceBoxItems icon={FitbandIcon} name={'Exercise'}/>
        </div>
    )
}

export default ServicesBox