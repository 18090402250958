import React from 'react'

// components
import './App.scss'
import Hero from './components/Hero'
import Content from './components/Content'
import Footer from './components/Footer'

const App = () => {
   return (
      <div className="main-container">
         
         <Hero />
         <Content />
         <Footer />
      </div>
   )
}

export default App