import React from 'react'

const ServicesBoxItems = ({ icon, name }) => {
    return (
        <div className="services-box__item">
            <img src={icon} alt={name}/>
            <p>{name}</p>
        </div>
    )
}

export default ServicesBoxItems