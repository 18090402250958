import React from 'react'
import BoxChampyLogo from '../images/boxchampy-logo.svg'

// icons
import HamburgerMenu from '../images/menu_icon.png'

const Navbar = () => {  
    return (
        <>
            <nav className="navbar">
                <div className="navbar__logo">
                    <a href='#'><img src={BoxChampyLogo} alt="BoxChampy Logo"/></a>
                    <a href='#' className="navbar__logo__site-name">{'BOXCHAMPY'}</a>
                </div>
                <div className="navbar__links">
                    <ul>
                        <li><a href='#'>HOME</a></li>
                        <li><a href='#'>ABOUT</a></li>
                        <li><a href='#'>LOCATIONS</a></li>
                        <li><a href='#'>CONTACT</a></li>
                        <li><a href='#' className="sign-up navbar__sign-up">SIGN UP</a></li>
                    </ul>
                </div>
                <div className="navbar__menu">
                    <img src={HamburgerMenu} alt="Hamburger Menu"/>
                </div>
            </nav>
        </>
    )
}

export default Navbar