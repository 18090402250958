import React from 'react'
import WomanBoxer from '../images/girl_fight.jpeg'

const Content = () => {
    return (
        <div className="content-item">
            <h1 id="first-heading">HOW WE GOT STARTED IN THIS BUSINESS</h1>
            <div className="content-item__lower">
               <img src={WomanBoxer} alt='Woman Boxer'/>
               <div className="content-item__lower__text">
                  <h1 id="second-heading">HOW WE GOT STARTED IN THIS BUSINESS</h1>
                  <p>Lorem ipsum dolor sit amet. Eos quam facere sit iusto mollitia aut tempore aspernatur ut saepe adipisci est eveniet rerum qui dicta minus est earum vitae. Qui perspiciatis voluptates ut sint eius est consequatur consequatur ad itaque asperiores quo<br/><br/>iusto perspiciatis est magnam sint quo dolor corrupti. Ut omnis quia ab beatae tempora non iusto facere ut molestiae quod.</p>
                  <a href='#' className="sign-up content-item__lower__text__sign-up">SIGN UP</a>
               </div>
            </div>
         </div>
    )
}

export default Content