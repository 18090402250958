import React from 'react'
import ServicesBox from './ServicesBox'
import Navbar from './Navbar'
import ManBoxer from '../images/man_boxer.png'

const Hero = () => {
    return (
        <div className="hero">
            <Navbar />
            {/* <h1>Fight like <br/> a <span>champion</span></h1> */}
            <img className="hero__img" src={ManBoxer} alt='Man Boxer'/>
            <ServicesBox />
        </div>
    )
}

export default Hero