import React from 'react'

// icons
import LinkedInIcon from '../images/linkedin_icon.svg'
import FacebookIcon from '../images/facebook_icon.svg'
import InstagramIcon from '../images/instagram_icon.svg'
import EmailIcon from '../images/email_icon.svg'

const Footer = () => {
    return (
        <div className="footer">
            <h1>READY FOR YOUR <span>NEXT</span> LESSON?</h1>
            <div className="footer__social-icons">
                  <a href="#"><img src={LinkedInIcon} alt="LinkedIn Icon"/></a>
                  <a href="#"><img src={InstagramIcon} alt="Instagram Icon"/></a>
                  <a href="#"><img src={FacebookIcon} alt="Facebook Icon"/></a>
            </div>
            <div className="footer__email">
               <img src={EmailIcon} alt="Email Icon" />
               <p>info@boxchampy.com</p>
            </div>
            <div className="footer__privacy-tc">
               <a href="#"><span>Privacy policy</span></a>
               <a href="#"><span>Terms & conditions</span></a>
            </div>
         </div>
    )
}

export default Footer